/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, SeparateLine, SeparateLineWrap, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-jomzxy css-1141mh4 --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/ba97cce348c349f4b398df814e34c816_s=3000x_.jpg);
    }
  
background-position: 50% 41%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Objavte krásu, ktorá žije v každom detaile<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} style={{"backgroundColor":"#e6e6e6"}} name={"3point"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/960a873e271d45efbd03796b91b0224d_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/960a873e271d45efbd03796b91b0224d_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-style: italic;\">Prírodný produkt</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/14681640819a48aba614b3b7746f38fb_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/14681640819a48aba614b3b7746f38fb_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"Jedinečný výrobok<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/6c17d9191a0543709f99e306f624739b_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/6c17d9191a0543709f99e306f624739b_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"Podľa predstáv zákazníka<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/b1f62fe56bca4935b936e9ca259c5a83_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/b1f62fe56bca4935b936e9ca259c5a83_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"Prémiová kvalita<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"#e6e6e6"}} name={"3lnwye8runx"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"#e6e6e6"}} name={"hik0u0afbhs"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Hodiny v oceľovom ráme<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":404}} content={"Jedinečné spojenie dreva a ocele v minimalistickom prevedení. Hodiny, ktoré dodajú priestoru modernú eleganciu.<br><br>&nbsp;Objednajte si jedinečný kúsok elegancie nižšie.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 btn-box--shadow5" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Hodiny<br>"} use={"page"} href={"/na-predaj"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":453}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/2b34bc4931374337a48bcd7cd4acb10b_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/2b34bc4931374337a48bcd7cd4acb10b_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/2b34bc4931374337a48bcd7cd4acb10b_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/2b34bc4931374337a48bcd7cd4acb10b_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/2b34bc4931374337a48bcd7cd4acb10b_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":461}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/337740f077864a33afd623811ff27f34_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/337740f077864a33afd623811ff27f34_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/337740f077864a33afd623811ff27f34_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/337740f077864a33afd623811ff27f34_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/337740f077864a33afd623811ff27f34_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Epoxidové Hodiny<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":404}} content={"Epoxidové hodiny spájajú prírodnú krásu dreva s moderným dizajnom. Luxusný doplnok, ktorý ozdobí váš interiér.<br><br>Objednajte si jedinečný kúsok elegancie nižšie.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 btn-box--cColor1 btn-box--shadow5" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Hodiny<br>"} use={"page"} href={"/na-predaj"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"backgroundColor":"#e6e6e6","paddingTop":0}} name={"cxvsgfxglhw"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Stoly"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":404}} content={"Ručne vyrábané stoly z masívneho dreva a epoxidu sú symbolom luxusu. Prepojenie funkčnosti a dizajnu pre váš domov.<br><br>Objednajte si jedinečný kúsok elegancie nižšie.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 btn-box--shadow5" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Stoly<br>"} use={"page"} href={"/stoly"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":461}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/39117/c53adff424574256af6daee53ae36002.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39117/59ef7f19c1e64509a61e4b702ebf3913_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Luxusné sviečky<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":404}} content={"Elegantné sviečky s jemnými vôňami a moderným dizajnom. Perfektný detail na dotvorenie útulnej atmosféry.<br><br>Objednajte si jedinečný kúsok elegancie nižšie.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 btn-box--shadow5" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Sviečky<br>"} use={"page"} href={"/sviecky"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"backgroundColor":"rgba(230,230,230,1)","paddingTop":0}} name={"cxvsgfxglhw"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Servírovacie dosky<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":404}} content={"Luxusné servírovacie dosky z dreva a živice povýšia každé stolovanie. Dokonalé na prezentáciu vašich kulinárskych výtvorov.<br><br>Objednajte si jedinečný kúsok elegancie nižšie.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 btn-box--shadow5" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Doplnky<br>"} use={"page"} href={"/doplnky"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39117/229c04168b4d4d6f8c4887840d249d4e_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--30 pt--30" style={{"backgroundColor":"var(--color-custom-2)"}} name={"informace-1"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=1400x_.png 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Konzultácia&nbsp;</span>"}>
              </Title>

              <Text className="text-box pb--12 pt--12" content={"<span style=\"color: var(--white);\">Máte nejakú individuálnu predstavu alebo požiadavku? Neváhajte nás kontaktovať. Vaše predstavy radi zrealizujeme.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn4 btn-box--shape4 mt--20" style={{"backgroundColor":"rgba(154,134,111,1)"}} content={"Kontaktovať"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}